

import { EventBus } from '@/plugins/eventBus'
import axios, { AxiosError, AxiosResponse } from "axios"
import { TiptapVuetify } from "tiptap-vuetify"
import TiptapConfigMixin from "@/mixins/TiptapConfigMixin.vue"
import Vue from "vue"
import { VueConstructor } from 'vue/types/umd'

interface Worker {
    id: number
    favorite_jobs: string[],
    availability_preferences: string[]|null,
    environment_preferences: string[]|null,
    start_preferences: string[]|null,
    knowledge_text: string|null,
    hard_skills_text: string|null,
    soft_skills_text: string|null,
    project_text: string|null,
    introduction_text: string|null,
    equipments: Equipment[]|null
}

interface Equipment {
    id: number,
    name: string|null,
    comment: string|null,
    size: string|null
}

type NewEquipment = Omit<Equipment,"id">


export default (Vue as VueConstructor<Vue & InstanceType<typeof TiptapVuetify>>).extend({
    name: "WorkerFormInterview",
    mixins: [TiptapConfigMixin],
    components:{TiptapVuetify},
    props:{
        id: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        saveWorkerData: {} as Worker,
        equipments: [] as Equipment[],
        focus: 0,
        workerReference: {} as Worker,
        worker: {} as Worker,
        equipmentsToCreate: [{name: null,size:null,comment:null}] as NewEquipment[],
        parts: ['questionnaire','knowledge','know_how','life_skills','how_to_become','introduction_text'],
        equivalence: {
            questionnaire: 'favorite_jobs',
            knowledge: 'knowledge_text',
            know_how: 'hard_skills_text',
            life_skills: 'soft_skills_text',
            how_to_become: 'project_text',
            introduction_text: 'introduction_text'
        },
        selectedPart: 'questionnaire',
        questionnaireParts: {
            start_preferences: ['one_hour','one_day','one_week','one_month','three_month'],
            availability_preferences: ['full_time','part_time','day','morning','afternoon','night','weekend','2_8_shift','3_8_shift','4_8_shift','5_8_shift'],
            environment_preferences: ['outdoor','indoor','cold','dust','noise','high','heavy_loads'],
            equipments: [] as Equipment[]
        },
        selectedPositions: null,
        workerUrl:'v1/workers/',
        workerFields: `?fields=favorite_jobs,availability_preferences,environment_preferences,start_preferences,knowledge_text,hard_skills_text,soft_skills_text,project_text,introduction_text`,
        equipmentUrl: '/equipments',
        nameMaxLength: 255,
        sizeMaxLength: 16,
        updateProcess: false,
        showRemoveButton: false,
        showDeleteButton: false,
        equipmentsToDelete: [] as string[],
        changeDetected: false,
        equipmentsToUpdate: [] as Equipment[]
    }),
    mounted() {
        // get worker and equipment data
        this.initialize();
    },
    watch: {
        id: {
            handler() {
                // important to worker list split pane
                this.initialize()
            },
        },
        worker: {
            handler() {
                this.changeDetected =true
            },
            deep:true
        }
    },
    methods: {
       async initialize() {
            this.resetData()
            // Get worker data
            await axios
                .get(`${this.workerUrl}${this.$props.id}${this.workerFields}`)
                .then((responseWorker: AxiosResponse) => {
                    this.worker = responseWorker.data
                    // create a clone object without link between them
                    let key: string
                    for(key in this.questionnaireParts) {
                        // check if worker's properties are same as questionnaireParts and have value
                        // and if not initialize to empty array
                        if (this.worker[key] === null) {
                            (this.worker[key] as string[]|Equipment[]) = []
                        }
                    }
                    this.workerReference = {... this.worker};
                })
                .catch((error: AxiosError) => {
                    EventBus.$emit('snackbar',{axiosError: error})
                })
            // Get associated equipments data
            await axios
                .get(`${this.workerUrl}${this.$props.id}${this.equipmentUrl}`)
                .then((responseEquipment: AxiosResponse) => {
                    this.equipments = responseEquipment.data
                })
                .catch((error: AxiosError) => {
                    EventBus.$emit('snackbar',{axiosError: error})
                })
        },
        removeNewEquipmentData() {
            // remove data of new equipment
            if (this.equipmentsToCreate.length > 1) {
                this.equipmentsToCreate.splice(this.focus,1)
            } else {
                this.equipmentsToCreate[this.focus] = {name:null,size:null,comment:null}
            }
            this.showRemoveButton = false
        },
        showNewEquipmentInput() {
            // check if last entry of equipmentsToCreate array have a new data
            this.changeDetected = true
            let lastIndex = this.equipmentsToCreate.length-1
            for (let input in this.equipmentsToCreate[lastIndex]) {
                if (this.equipmentsToCreate[lastIndex][input as keyof NewEquipment] !== null ) {
                    this.equipmentsToCreate.push({name:null,size:null,comment:null})
                    break;
                }
            }
        },
        showRemoveButtonMethod(index: number) {
           this.showRemoveButton = true
           this.focus = index
        },
        showDeleteButtonMethod(equipmentId: number) {
           this.showDeleteButton = true
           this.focus = equipmentId
        },
        checkUpdate(id: number) {
            // check if worker's data change
            if (!this.equipmentsToUpdate.includes(id)) {
                this.changeDetected = true
                this.equipmentsToUpdate.push(id)
                this.changeDetected = true
            }
        },
        equipmentsToDeleteMethod(equipmentId: number) {
            // add equipment id into delete equipment list
            this.changeDetected = true
            this.equipmentsToDelete.push(equipmentId)
            // remove deleted equipment from front
            this.equipments = this.equipments.map((equipment: Equipment) => {
                if (equipment.id !== equipmentId) {
                    return equipment
                }
            })
            this.equipments = this.equipments.filter((data: Equipment) => !!data)
            this.showDeleteButton = false
        },
        async save() {
            let isUpdate = false
            // Equipment treatment
            // Delete equipment process
            if (this.equipmentsToDelete.length > 0) {
                isUpdate = true
               await axios.all(
                    this.equipmentsToDelete.map((equipmentId: string) => 
                        axios.delete(`${this.workerUrl}${this.$props.id}${this.equipmentUrl}/${equipmentId}`)
                    .then((response: AxiosResponse) => {
                        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.equipments.action.success_deleted')})
                    }).catch((error: AxiosError) => {
                        EventBus.$emit('snackbar',{axiosError: error})
                    })
                ))
            }
            // Post equipment process
            // need check > 1 to take account empty equipment object
            if (this.equipmentsToCreate.length > 1) {
                isUpdate = true
                // remove empty new equipment to not trigger post process
                this.equipmentsToCreate.pop()
               await axios.all(
                    this.equipmentsToCreate.map((newEquipment: Equipment) => {
                        axios.post(`${this.workerUrl}${this.$props.id}${this.equipmentUrl}`, newEquipment)
                            .then((response: AxiosResponse) => {
                                EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.equipments.action.success_add')})
                            }).catch((error: AxiosError) => {
                                EventBus.$emit('snackbar',{axiosError: error})
                            })
                    }
                ))
            }
            // Update equipment process
            if (this.equipmentsToUpdate.length > 0) {
                isUpdate = true
                let bodyUpdateEquipments = this.equipments.map((workerEquipment: Equipment) => {
                    if (this.equipmentsToUpdate.includes(workerEquipment.id)) {
                        return workerEquipment
                    }
                })
                bodyUpdateEquipments = bodyUpdateEquipments.filter((data: Equipment) => !!data)
                   await axios.all(
                        bodyUpdateEquipments.map((updateEquipment: Equipment) => {
                            let equipmentId = updateEquipment.id
                            // delete id property to pass valid data to update api
                            delete updateEquipment.id
                            axios.put(`${this.workerUrl}${this.$props.id}${this.equipmentUrl}/${equipmentId}`,updateEquipment)
                                .then((response: AxiosResponse) => {
                                    EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.equipments.action.success_update')})
                                }).catch((error: AxiosError) => {
                                    EventBus.$emit('snackbar',{axiosError: error})
                                })
                    }
                ))
            }

            // check if worker data has change and get property change
            let updateWorkerData : {[key: string]: string|string[] } = {}
            let isUpdatedWorker = false
            for (let property in this.workerReference) {
                if (this.workerReference[property] !== this.worker[property]) {
                    isUpdate= true
                    isUpdatedWorker = true
                    updateWorkerData[property] = this.worker[property]
                }
            }

            if (isUpdatedWorker) {
               await axios.put(`${this.workerUrl}${this.$props.id}`,updateWorkerData)
                    .then((response: AxiosResponse) => {
                        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.update_success')})
                    }).catch((error: AxiosError) => {
                        EventBus.$emit('snackbar',{axiosError: error})
                    })
            }

            // get data after update
            if (isUpdate) {
                this.initialize()
            }
        },
        resetData() {
            // reset all data
            this.changeDetected = this.showRemoveButton = this.showDeleteButton = false
            this.equipmentsToDelete = []
            this.equipmentsToUpdate = []
            this.equipmentsToCreate = [{name:null,size:null,comment:null}]
        }
    }
})
